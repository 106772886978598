import React from "react";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker
} from "react-google-maps";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookSquare,
  faTwitterSquare,
  faInstagram
} from "@fortawesome/free-brands-svg-icons";

const CustomSkinMap = withScriptjs(
  withGoogleMap(() => (
    <GoogleMap
      defaultZoom={13}
      defaultCenter={{ lat: 40.748817, lng: -73.985428 }}
      defaultOptions={{
        scrollwheel: false,
        zoomControl: true,
        styles: [
          {
            featureType: "water",
            stylers: [
              { saturation: 43 },
              { lightness: -11 },
              { hue: "#0088ff" }
            ]
          },
          {
            featureType: "road",
            elementType: "geometry.fill",
            stylers: [
              { hue: "#ff0000" },
              { saturation: -100 },
              { lightness: 99 }
            ]
          },
          {
            featureType: "road",
            elementType: "geometry.stroke",
            stylers: [{ color: "#808080" }, { lightness: 54 }]
          },
          {
            featureType: "landscape.man_made",
            elementType: "geometry.fill",
            stylers: [{ color: "#ece2d9" }]
          },
          {
            featureType: "poi.park",
            elementType: "geometry.fill",
            stylers: [{ color: "#ccdca1" }]
          },
          {
            featureType: "road",
            elementType: "labels.text.fill",
            stylers: [{ color: "#767676" }]
          },
          {
            featureType: "road",
            elementType: "labels.text.stroke",
            stylers: [{ color: "#ffffff" }]
          },
          { featureType: "poi", stylers: [{ visibility: "off" }] },
          {
            featureType: "landscape.natural",
            elementType: "geometry.fill",
            stylers: [{ visibility: "on" }, { color: "#b8cb93" }]
          },
          { featureType: "poi.park", stylers: [{ visibility: "on" }] },
          {
            featureType: "poi.sports_complex",
            stylers: [{ visibility: "on" }]
          },
          { featureType: "poi.medical", stylers: [{ visibility: "on" }] },
          {
            featureType: "poi.business",
            stylers: [{ visibility: "simplified" }]
          }
        ]
      }}
    >
      <Marker position={{ lat: 40.748817, lng: -73.985428 }} />
    </GoogleMap>
  ))
);

export default function Maps() {
  return (
    <div>
      <div>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3532.085379184237!2d85.33649361453857!3d27.714650031778927!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb19fc3b03e37f%3A0x6b18d33e605d62cc!2sDeerwalk%20Auto%20Services!5e0!3m2!1sen!2snp!4v1571999709792!5m2!1sen!2snp"
          height="300"
          frameborder="0"
          style={{ border: 0, width: "100vw" }}
        ></iframe>
      </div>
      <div>
        <hr color="white"></hr>
        <div className="container">
          <h6 align="center">
            <strong> Contact Us:</strong>
          </h6>
          <div className="text-center">
            <table align="center">
              <tr>Tel: 4499766</tr>
              <tr>Cell: 982-303-1249 (Aman Ghimire)</tr>
              <tr>984-646-0993 (DB Thakuri)</tr>
              <tr>Fax: 4469628</tr>
              <tr>Kalopul-7, Chabahil, Kathmandu</tr>
            </table>
          </div>
          <div className="footer mt-2">
            <div className="text-center">
              <strong>Follow us on:</strong>
            </div>
            <div className="col-md-7 d-flex justify-content-end pr-4 py-1">
              <a
                href="https://www.facebook.com/deerwalkautoservices/"
                className="pr-4"
              >
                <FontAwesomeIcon icon={faFacebookSquare} />
              </a>
              <a href="https://twitter.com/DeerwalkS/" className="pr-4">
                <FontAwesomeIcon icon={faTwitterSquare} />
              </a>
              <a href="instagram.com/deerwalkautoservices/" className="pr-4">
                <FontAwesomeIcon icon={faInstagram} />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
