import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";

import StepWizard from "react-step-wizard";
import Step1 from "./step1";
import Step2 from "./step2";
import Step3 from "./step3";
export default class Booking extends React.Component {
  state = {
    vehicle: {},
    customer: {}
  };

  setVehicle = vehicle => {
    this.setState({
      vehicle: vehicle
    });
  };
  setCustomer = customer => {
    this.setState({
      customer: customer
    });
  };

  render() {
    console.log("Booking state", this.state);
    return (
      <StepWizard>
        <Step1
          setCustomer={this.setCustomer}
          setVehicle={this.setVehicle}
          vehicle={this.state.vehicle}
        />
        <Step2
          setCustomer={this.setCustomer}
          customer={this.state.customer && this.state.customer}
          vehicle={this.state.vehicle}
        />
        <Step3
          vehicle={this.state.vehicle && this.state.vehicle}
          customer={this.state.customer && this.state.customer}
        />
      </StepWizard>
    );
  }
}
