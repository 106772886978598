import React, { Component } from "react";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import "bootstrap/dist/css/bootstrap.min.css";
import api from "../../api";
import swal from "sweetalert";
import moment from "moment";

export default class Feedback extends Component {
  state = {
    vehicle: {},
    vehicle_no: "",
    full_name: "",
    contact_no: "",
    service_satisfaction: null,
    costTiming_satisfaction: null,
    recommendation: null,
    suggestion: null,
    loading: false,
    next: false,
    redirectToReferrer: false
  };

  getVehicleDetail = vehicle_no => {
    let slug = vehicle_no
      .toLowerCase()
      .split(" ")
      .join("-");
    api
      .get("api/customer/vehicle/" + slug)
      .then(response1 => {
        api.get("api/customer/" + response1.data.customer).then(response => {
          this.setState({
            ...this.state,
            customer: response.data.id,
            vehicle: response1.data,
            full_name: response.data.full_name,
            contact_no: response.data.contact_no,
            loading: false
          });
        });
      })
      .catch(error => {
        console.log(error);
      });
  };

  handleChange = e => {
    this.setState({
      ...this.state,
      [e.target.name]: e.target.value
    });
    // this.getVehicleDetail();
  };

  handleBlur = e => {
    this.setState({
      loading: true
    });

    this.getVehicleDetail(e.target.value);
    setTimeout(() => {
      // const vehicle = this.state.vehicles
      //   .filter(vehicle => vehicle.vehicle_no === this.state.vehicle_no)
      //   .then(res => {
      //     this.props.setFeedback(res.data);
      //     this.setState({
      //       ...this.state,
      //       loading: false,
      //       vehicle_no: vehicle[0].vehicle_no,
      //       // full_name: vehicle[0].full_name,
      //       // contact: vehicle[0].contact,
      //       loading: false
      //     });
      //   });
    }, 500);
  };

  handleSubmit = e => {
    e.preventDefault();
    const feedback = {
      // vehicle_no: this.state.vehicle_no,
      // full_name: this.state.full_name,
      // contact_no: this.state.contact_no,
      feedback_date: moment().format("YYYY-MM-DD"),
      service_satisfaction:
        this.state.service_satisfaction == "true" ? true : false,
      costTiming_satisfaction:
        this.state.costTiming_satisfaction == "true" ? true : false,
      recommendation: this.state.recommendation == "true" ? true : false,
      suggestion: this.state.suggestion,
      customer: this.state.customer
    };
    console.log("Feedback", feedback);

    api
      .post("/api/customer/feedback/", feedback)
      .then(respose => {
        // console.log(response);
        // console.log(response.data);
        this.setState({
          vehicle: {},
          vehicle_no: "",
          full_name: "",
          contact_no: "",
          service_satisfaction: null,
          costTiming_satisfaction: null,
          recommendation: null,
          suggestion: null,
          loading: false,
          next: false,
          redirectToReferrer: false
        });
        this.props.history.push("/admin/feedback");
        // })
        // .catch(err => alert(err));
        swal({
          text: "Your feedback has been submited !!",
          icon: "success"
        });
      })
      .catch(error => {
        swal({
          text: "Failed to submit your feedback !!",
          icon: "error"
        });
      });
  };

  next = e => {
    if (this.state.vehicle_no) {
      this.setState({
        next: !this.state.next
        // next: false
      });
    }
  };

  render() {
    console.log(this.state);

    return (
      <div>
        <h3>Feedback</h3>
        <hr color="blue"></hr>
        <form onSubmit={this.handleSubmit}>
          {!this.state.next && (
            <div>
              <div className="form-group">
                <label>Vehicle No</label>
                <input
                  type="text"
                  name="vehicle_no"
                  minLength="4"
                  value={this.state.vehicle_no}
                  onChange={this.handleChange}
                  className="form-control"
                  disabled={this.state.update}
                  onBlur={this.handleBlur}
                  required={true}
                />
              </div>

              <div className="form-group">
                <label>Full Name</label>
                <input
                  type="text"
                  name="full_name"
                  value={this.state.full_name}
                  onChange={this.handleChange}
                  className="form-control"
                  disabled={true}
                />
              </div>
              <div className="form-group">
                <label>Contact </label>
                <input
                  type="text"
                  name="contact_no"
                  minLength="6"
                  maxLength="10"
                  value={this.state.contact_no}
                  onChange={this.handleChange}
                  className="form-control"
                  disabled={true}
                />
              </div>
              <div className="d-flex">
                <p>
                  <button
                    className="btn btn-primary btn-block"
                    type="submit"
                    onClick={this.next}
                  >
                    Next
                  </button>
                </p>
              </div>
            </div>
          )}

          {this.state.next && (
            <div className="form-group">
              <p>Are you satisfied with our customer's service?</p>
              <label className="radio-inline ml-2">
                <input
                  type="radio"
                  name="service_satisfaction"
                  value={true}
                  onChange={this.handleChange}
                  required={true}
                />
                &nbsp;&nbsp;Yes
              </label>
              <label className="radio-inline ml-3">
                <input
                  type="radio"
                  name="service_satisfaction"
                  value={false}
                  onChange={this.handleChange}
                  required={true}
                />
                &nbsp;&nbsp;No
              </label>

              <div className="form-group">
                <p>Are you satisfied with our service cost and timings?</p>
                <label className="radio-inline ml-2">
                  <input
                    type="radio"
                    name="costTiming_satisfaction"
                    value={true}
                    onChange={this.handleChange}
                    required={true}
                  />
                  &nbsp;&nbsp;Yes
                </label>
                <label className="radio-inline ml-3">
                  <input
                    type="radio"
                    name="costTiming_satisfaction"
                    value={false}
                    onChange={this.handleChange}
                  />
                  &nbsp;&nbsp;No
                </label>
              </div>

              <div className="form-group">
                <p>Do you recommend our service to others?</p>
                <label className="radio-inline ml-2">
                  <input
                    type="radio"
                    name="recommendation"
                    className="form-check-label"
                    value={true}
                    onChange={this.handleChange}
                    required={true}
                  />
                  &nbsp;&nbsp;Yes
                </label>
                <label className="radio-inline ml-3">
                  <input
                    type="radio"
                    name="recommendation"
                    className="form-check-label"
                    value={false}
                    onChange={this.handleChange}
                    required={true}
                  />
                  &nbsp;&nbsp;No
                </label>
              </div>

              <div className="form-group">
                <label>Suggestions... </label>
                <textarea
                  type="text"
                  row="5"
                  name="suggestion"
                  className="form-control"
                  value={this.state.suggestion}
                  onChange={this.handleChange}
                />
              </div>
              <button
                className="btn btn-success btn-primary d-flex"
                type="submit"
              >
                Submit
              </button>
            </div>
          )}
        </form>
      </div>
    );
  }
}
