import React, { Component } from "react";
import "date-fns";

import {
  Input,
  FormGroup,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Table
} from "reactstrap";

import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import DatePicker from "react-datepicker";
import "./style.css";
import Select from "react-select";

export default class JobCardForm extends Component {
  state = {
    joborder: "",
    registration_number: "",
    reference_person: "",
    reference_person_contact: "",
    vat_number: "",
    chasis: "",
    booking_service: "",
    startDate: new Date(),
    startTime: "",
    service_time: new Date(),
    services: this.props.serviceValue ? this.props.serviceValue : []
  };

  handleDate = date => {
    console.log("date", date);
    this.setState({
      ...this.state,
      startDate: date
    });
  };

  handleTimeChange = date => {
    console.log("time", date);
    this.setState({
      service_time: date
    });
  };

  handleFormChange = e => {
    console.log(e);
    let key = e.target.name;
    this.setState({
      ...this.state,
      [key]: e.target.value
    });
  };

  handleServiceFormChange = (key, value) => {
    this.setState({
      ...this.state,
      [key]: value
    });
  };

  handleFormSubmit = e => {
    e.preventDefault();
    console.log("from form", this.state);
    this.props.add(this.state);
  };

  render() {
    console.log(this.state);
    return (
      <div>
        <form onSubmit={this.handleFormSubmit}>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <Label for="date">Date</Label>
                <br />
                <DatePicker
                  className="form-control"
                  selected={this.state.startDate}
                  onChange={date => this.handleDate(date)}
                  dateFormat="MM/dd/yyyy"
                  placeholderText="Select Date"
                />
              </div>
            </div>

            <div className="col-md-6">
              <div className="form-group">
                <label for="Time picker"> Time</label>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardTimePicker
                    margin="normal"
                    mask="__:__ _M"
                    name="service_time"
                    value={this.state.service_time}
                    onChange={this.handleTimeChange}
                    KeyboardButtonProps={{
                      "aria-label": "change time"
                    }}
                  />
                </MuiPickersUtilsProvider>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <Label for="refperson">Ref. Person Name</Label>
                <input
                  className="form-control"
                  type="text"
                  name="reference_person"
                  onChange={this.handleFormChange}
                  value={this.state.reference_person}
                />
              </div>
            </div>

            <div className="col-md-6">
              <div className="form-group">
                <Label for="refcontact">Ref. Person Contact</Label>
                <input
                  type="text"
                  name="reference_person_contact"
                  minLength="6"
                  maxLength="10"
                  className="form-control"
                  onChange={this.handleFormChange}
                  value={this.state.reference_person_contact}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <Label for="vatno">VAT No.</Label>
                <input
                  type="text"
                  name="vat_number"
                  className="form-control"
                  onChange={this.handleFormChange}
                  value={this.state.vat_number}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <Label for="chassisno">Chassis No.</Label>
                <input
                  type="text"
                  name="chasis"
                  className="form-control"
                  required
                  onChange={this.handleFormChange}
                  value={this.state.chasis}
                />
              </div>
            </div>
          </div>
          <div className="form-group">
            <Label for="services">Services</Label>
            <Select
              isMulti
              name="services"
              onChange={value =>
                this.handleServiceFormChange("services", value)
              }
              value={this.state.services}
              options={this.props.serviceOption}
              isClearable={true}
              isSearchable={true}
            />
          </div>
          <br />
          <ModalFooter>
            <Button
              color="primary"
              disabled={this.props.disableButton}
              type="submit"
            >
              Create Job Card
            </Button>{" "}
            <Button color="danger" onClick={this.props.close}>
              Cancel
            </Button>
          </ModalFooter>
        </form>
        <div></div>
      </div>
    );
  }
}
