import React, { Component } from "react";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import api from "../../api";

export default class Step1 extends Component {
  state = {
    models: [],
    models_option: [],
    vehicles: [],
    companies: [],
    companies_option: [],
    years: [],
    update: false,
    newBookData: {
      vehicle_no: "",
      company_name: "",
      made_year: "",
      model_names: "",
      fuel_type: "",
      mileage: "",
      loading: false
    }
  };

  componentDidMount = () => {
    this.getCompanyList();
    this.getModelList();
    this.getVehicleList();
  };

  getCompanyList = () => {
    api
      .get("api/staff/companyname/")
      .then(response => {
        this.setState({
          companies_option: response.data.map(data => ({
            value: data.id,
            label: data.company_name
          })),
          companies: response.data
        });
      })
      .catch(err => alert(err));
  };

  getModelList = () => {
    api
      .get("api/staff/modelname/")
      .then(response => {
        console.log(response);
        this.setState({
          models_option: response.data.map(data => ({
            value: data.id,
            label: data.model_name,
            company: data.company
          })),
          models: response.data
        });
      })
      .catch(err => alert(err));
  };

  getVehicleList = () => {
    api.get("api/customer/vehicle/").then(response => {
      this.setState({
        vehicles: response.data
      });
    });
  };

  handleChange = e => {
    this.setState({
      ...this.state,
      newBookData: {
        ...this.state.newBookData,
        [e.target.name]: e.target.value
      }
    });
  };

  handleSelectChange = (val, key) => {
    console.log("Selected: " + val);
    this.setState({
      ...this.state,
      newBookData: {
        ...this.state.newBookData,
        [key]: val
      }
    });
  };

  handleBlur = e => {
    this.setState({
      ...this.state,
      loading: true
    });

    setTimeout(() => {
      const vehicle =
        this.state.vehicles &&
        this.state.vehicles.filter(
          vehicle =>
            vehicle.vehicle_no.toLowerCase() ===
            this.state.newBookData.vehicle_no.toLowerCase()
        );
      if (vehicle.length > 0) {
        // localStorage.setItem("vehicle", JSON.stringify(vehicle[0]));
        this.props.setVehicle(vehicle[0]);
        api.get("api/customer/" + this.props.vehicle.customer).then(res => {
          this.props.setCustomer(res.data);
          this.setState({
            ...this.state,
            loading: false,
            update: true,
            newBookData: {
              vehicle_no: vehicle[0].vehicle_no,
              company_name: vehicle[0].company_name,
              made_year: vehicle[0].made_year,
              model_names: vehicle[0].model_name,
              fuel_type: vehicle[0].fuel_type,
              mileage: vehicle[0].mileage,
              loading: false
            }
          });
        });

        // this.props.goToStep(2);
      } else {
        this.setState({
          ...this.state,
          loading: false
        });
      }
    }, 500);
  };

  handleSubmit = e => {
    e.preventDefault();
    const vehicle = {
      vehicle_no: this.state.newBookData.vehicle_no,
      company_name: this.state.newBookData.company_name.value,
      made_year: parseInt(this.state.newBookData.made_year.value),
      model_names: this.state.newBookData.model_names.value,
      fuel_type: this.state.newBookData.fuel_type.value,
      mileage: this.state.newBookData.mileage
    };
    api.post("api/customer/vehicle/", vehicle).then(response => {
      // localStorage.setItem("vehicle", JSON.stringify(response.data));
      this.props.setVehicle(response.data);
      this.props.nextStep();
      console.log("Successful !!!");
    });
  };

  handleUpdate = e => {
    e.preventDefault();
    const slug = this.props.vehicle.vehicle_no
      .toLowerCase()
      .split(" ")
      .join("-");
    api
      .patch("api/customer/vehicle/" + slug, {
        mileage: this.state.newBookData.mileage
      })
      .then(response => {
        this.props.nextStep();
      })
      .catch(err => alert(err));
  };

  render() {
    console.log("Step 1 props", this.props);
    console.log("Step 1 state", this.state);
    const N = new Date().getFullYear();
    let years = [];
    for (let i = 1980; i <= N; i++) {
      years.push({ value: i, label: i });
    }
    return (
      <div>
        {this.state.loading ? (
          <div
            style={{
              position: "fixed",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              top: 0,
              left: 0,
              zIndex: 9999,
              width: "100%",
              height: "100%",
              background: "white"
            }}
          >
            <Loader type="Puff" color="#00BFFF" height={100} width={100} />
          </div>
        ) : (
          <div className="container auto">
            <h3>Vehicle Details</h3>
            <hr color="blue"></hr>
            <form
              onSubmit={
                !this.state.update ? this.handleSubmit : this.handleUpdate
              }
            >
              <div className="form-group">
                <label>Vehicle No</label>
                <input
                  type="text"
                  name="vehicle_no"
                  minLength="4"
                  value={this.state.newBookData.vehicle_no}
                  onChange={this.handleChange}
                  className="form-control"
                  disabled={this.state.update}
                  onBlur={this.handleBlur}
                  required={true}
                />
              </div>
              {!this.state.update && (
                <div>
                  <div className="form-group">
                    <label>Company</label>
                    <CreatableSelect
                      name="company_name"
                      value={this.state.newBookData.company_name}
                      options={this.state.companies_option}
                      onChange={val =>
                        this.handleSelectChange(val, "company_name")
                      }
                      isClearable={true}
                      isSearchable={true}
                      required={true}
                    />
                  </div>

                  <div className="form-group">
                    <label>Make Year</label>
                    <Select
                      name="made_year"
                      value={this.state.newBookData.made_year}
                      options={years.reverse()}
                      // className="form-control"
                      onChange={val =>
                        this.handleSelectChange(val, "made_year")
                      }
                      isClearable={true}
                      isSearchable={true}
                      required={true}
                    />
                  </div>

                  <div className="form-group">
                    <label>Model Name</label>
                    <CreatableSelect
                      name="model_names"
                      value={this.state.newBookData.model_names}
                      options={
                        this.state.newBookData.company_name
                          ? this.state.models_option.filter(
                              model =>
                                model.company ==
                                this.state.newBookData.company_name.value
                            )
                          : this.state.models_option
                      }
                      onChange={val =>
                        this.handleSelectChange(val, "model_names")
                      }
                      isClearable={true}
                      isSearchable={true}
                      required={true}
                    />
                  </div>
                  <div className="form-group"></div>

                  <label>Fuel Type</label>
                  <Select
                    name="fuel_type"
                    value={this.state.newBookData.fuel_type}
                    options={[
                      { label: "Petrol", value: "petrol" },
                      { label: "Disel", value: "disel" }
                    ]}
                    onChange={val => this.handleSelectChange(val, "fuel_type")}
                    isClearable={true}
                    isSearchable={true}
                    required={true}
                  />
                </div>
              )}{" "}
              <div className="form-group">
                <label>Mileage (Km)</label>
                <input
                  type="text"
                  name="mileage"
                  value={this.state.newBookData.mileage}
                  className="form-control"
                  onChange={this.handleChange}
                  minLength="1"
                  maxLength="10"
                />
              </div>
              <div className="d-flex">
                {
                  // <p>
                  //   <button onClick={this.props.previousStep}>Back</button>
                  // </p>
                }
                <p className="ml-2">
                  <button className="btn btn-success btn-block" type="submit">
                    Next
                  </button>
                </p>
              </div>
            </form>
          </div>
        )}
      </div>
    );
  }
}
