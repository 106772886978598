// @material-ui/icons
import Book from "@material-ui/icons/Book";
import List from "@material-ui/icons/List";
import Person from "@material-ui/icons/Person";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import LocationOn from "@material-ui/icons/LocationOn";

// core components/views for User layout
import Dash from "views/Dashboard/Dashboard.js";
import Booking from "views/Booking/Booking";
import MyBooking from "views/MyBooking/MyBooking";
import Feedback from "views/FeedBack/Feedback";
import OurService from "views/OurService/OurService";
import Facility from "views/Facility/Facility";
import Maps from "views/Maps/Maps.js";

// core components/views for Admin layout
import AdminDashboard from "views/AdminDashboard/AdminDashboard";
import User from "views/User/User";
import Company from "views/Company/Company";
import Model from "views/Modal/Model";
import Service from "views/Service/Service";
import Feeds from "views/Feeds/Feeds.js";
import Login from "views/Login/Login";
import Logout from "views/Login/Logout";

const dashboardRoutes = [
  {
    path: "/dash",
    exact: true,
    layout: "/admin",
    name: "Dashboard",
    rtlName: "لوحة القيادة",
    icon: "fa fa-edit",
    component: Dash,
    user: false,
    admin: false
  },

  {
    path: "/booking",
    name: "Booking",
    rtlName: "لوحة القيادة",
    icon: Book,
    component: Booking,
    layout: "/admin",
    user: true,
    admin: true
  },

  {
    path: "/mybooking",
    name: "MyBooking",
    rtlName: "لوحة القيادة",
    icon: List,
    component: MyBooking,
    layout: "/admin",
    user: true,
    admin: false
  },

  {
    path: "/admindashboard",
    name: "Admin",
    rtlName: "لوحة القيادة",
    icon: Person,
    component: AdminDashboard,
    layout: "/admin",
    admin: true
  },

  {
    path: "/user",
    name: "User",
    rtlName: "ملف تعريفي للمستخدم",
    icon: SupervisorAccountIcon,
    component: User,
    layout: "/admin",
    admin: true
  },

  {
    path: "/company",
    name: "Company",
    rtlName: "لوحة القيادة",
    icon: "business",
    component: Company,
    layout: "/admin",
    admin: true
  },

  {
    path: "/model",
    name: "Model",
    rtlName: "لوحة القيادة",
    icon: "directions_car",
    component: Model,
    layout: "/admin",
    admin: true
  },

  {
    path: "/service",
    name: "Services",
    rtlName: "قائمة الجدول",
    icon: "content_paste",
    component: Service,
    layout: "/admin",
    admin: true
  },

  {
    path: "/ourservice",
    name: " Our Services",
    rtlName: "قائمة الجدول",
    icon: "build",
    component: OurService,
    layout: "/admin",
    user: true,
    admin: false
  },

  {
    path: "/facility",
    name: "Facility",
    rtlName: "طباعة",
    icon: "loyalty",
    component: Facility,
    layout: "/admin",
    user: true,
    admin: false
  },

  {
    path: "/feedback",
    name: "Feedback",
    rtlName: "الرموز",
    icon: "feedback",
    component: Feedback,
    layout: "/admin",
    user: true
  },

  {
    path: "/feeds",
    name: "Feeds",
    rtlName: "الرموز",
    icon: "feedback",
    component: Feeds,
    layout: "/admin",
    admin: true
  },

  {
    path: "/maps",
    name: "Contact Us",
    rtlName: "خرائط",
    icon: LocationOn,
    component: Maps,
    layout: "/admin",
    user: true,
    admin: true
  },

  {
    path: "/login",
    name: " Admin Login",
    rtlName: "إخطارات",
    icon: Person,
    component: Login,
    layout: "/admin",
    user: true,
    admin: false
  },

  {
    path: "/logout",
    name: "Logout",
    rtlName: "إخطارات",
    icon: "lock",
    component: Logout,
    layout: "/admin",
    admin: true
  }
];

export default dashboardRoutes;
