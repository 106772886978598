import React, { Component } from "react";
import api from "../../api";

export default class MyBooking extends Component {
  state = {
    vehicle_no: "",
    full_name: "",
    contact_no: "",
    booking_date: "",
    service_type: "",
    status: "",
    pickup_type: "",
    pickup_location: "",
    services: "",
    remarks: "",
    jobcard_services: [],
    jobcard_status: "",
    bookings: [],
    jobcards: [],
    bookingData: {},
    jobcardData: {},
    servicelist: []
  };

  componentDidMount = () => {
    this.getBookingDetails();
    this.getJobCardDetails();
    this.getServiceList();
  };
  getVehicleList = () => {
    api.get("api/customer/vehicle/").then(response => {
      this.setState({
        vehicles: response.data
      });
    });
  };

  getVehicleDetail = vehicle_no => {
    let slug = vehicle_no
      .toLowerCase()
      .split(" ")
      .join("-");
    api
      .get("api/customer/vehicle/" + slug)
      .then(response1 => {
        api.get("api/customer/" + response1.data.customer).then(response => {
          this.setState({
            ...this.state,
            vehicle: response1.data,
            full_name: response.data.full_name,
            contact_no: response.data.contact_no,
            loading: false
          });
        });
      })
      .catch(error => {
        console.log(error);
      });
  };

  getBookingDetails = () => {
    api.get("api/customer/booking/").then(response => {
      this.setState({
        bookings: response.data
      });
    });
  };

  getServiceList = () => {
    api
      .get("api/staff/servicetype/")
      .then(response => {
        console.log("services", response.data);
        let temp = response.data.map(data => ({
          value: data.id,
          label: data.service_type
        }));
        this.setState({
          servicelist: response.data,
          tempServices: temp
        });
      })
      .catch(err => alert(err));
  };
  getServiceString = arr => {
    if (arr.length > 0) {
      var services =
        this.state.servicelist &&
        this.state.servicelist.filter(service => arr.includes(service.id));
      if (services && services.length > 0) {
        let data = services.map(s => {
          if (s.hasOwnProperty("service_type")) {
            return s.service_type;
          }
        });
        return data && data.join(", ");
      }
    }
  };

  getJobCardDetails = () => {
    api.get("api/staff/jobcard/").then(response => {
      this.setState({
        jobcards: response.data
      });
    });
  };

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  handleBlur = e => {
    this.setState({
      loading: true
    });
    this.getVehicleDetail(e.target.value);
  };

  handleSubmit = e => {
    e.preventDefault();
    let jobcardData =
      this.state.jobcards &&
      this.state.jobcards.filter(
        jobcard =>
          jobcard.registration_number.toLowerCase() ===
          this.state.vehicle_no.toLowerCase()
      )[0];

    let bookingData =
      this.state.bookings &&
      this.state.bookings.filter(
        booking =>
          booking.vehicle.toLowerCase() === this.state.vehicle_no.toLowerCase()
      )[0];
    const myData = {
      vehicle_no: this.state.vehicle_no,
      contact_no: this.state.contact_no,
      jobcardData: jobcardData,
      bookingData: bookingData
    };
    this.setState({
      ...this.state,
      bookingData: bookingData,
      jobcardData: jobcardData
    });
  };

  render() {
    console.log(this.state);
    return (
      <div>
        <h3>Booking Updates</h3>
        <hr color="blue"></hr>
        <form onSubmit={this.handleSubmit}>
          <div className="form-group">
            <label>Vehicle No</label>
            <input
              type="text"
              name="vehicle_no"
              required={true}
              className="form-control"
              value={this.state.vehicle_no}
              onChange={this.handleChange}
              isClearable={true}
              onBlur={this.handleBlur}
            />
          </div>
          <div className="form-group">
            <label>Contact</label>
            <input
              type="text"
              name="contact_no"
              className="form-control"
              value={this.state.contact_no}
              onChange={this.handleChange}
              disabled={true}
              isClearable={true}
            />
          </div>

          <div className="d-flex">
            <p className="ml-2">
              <button
                disabled={!this.state.contact_no}
                className="btn btn-success btn-block"
                type="submit"
              >
                Show Details
              </button>
            </p>
          </div>
        </form>
        {Object.keys(this.state.bookingData && this.state.bookingData).length >
          0 && (
          <div>
            <h4>Booking Details</h4>
            <hr />
            <div className="row">
              <div className="col-md-3 font-weight-bold">Vehicle Number:</div>
              <div className="col-md-3">{this.state.vehicle_no}</div>
            </div>
            <div className="row">
              <div className="col-md-3 font-weight-bold">Customer Name:</div>
              <div className="col-md-3">{this.state.full_name}</div>
            </div>
            <div className="row">
              <div className="col-md-3 font-weight-bold">Contact:</div>
              <div className="col-md-3">{this.state.contact_no}</div>
            </div>
            <div className="row">
              <div className="col-md-3 font-weight-bold">Booking Date:</div>
              <div className="col-md-3">
                {this.state.bookingData.booking_date}
              </div>
            </div>
            <div className="row">
              <div className="col-md-3 font-weight-bold">Services:</div>
              <div className="col-md-3">
                {this.getServiceString(this.state.bookingData.services)}
              </div>
            </div>

            <div className="row">
              <div className="col-md-3 font-weight-bold">Status:</div>
              <div className="col-md-3">{this.state.bookingData.status}</div>
            </div>

            <div className="row">
              <div className="col-md-3 font-weight-bold">Remarks:</div>
              <div className="col-md-3">{this.state.bookingData.remarks}</div>
            </div>

            <div className="row">
              <div className="col-md-3 font-weight-bold">Updated Services:</div>
              <div className="col-md-3">
                {this.state.jobcardData &&
                  this.getServiceString(
                    this.state.jobcardData.jobcard_services
                      .split(",")
                      .map(dat => parseInt(dat))
                  )}
              </div>
            </div>
            <div className="row">
              <div className="col-md-3 font-weight-bold">Current Status:</div>
              <div className="col-md-3">
                {this.state.jobcardData &&
                  this.state.jobcardData.jobcard_status}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
