import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import color from "@material-ui/core/colors/amber";
import "./style.css";

export default function Dashboard() {
  return (
    <div className="background">
      <div className="row dark text-center intraTopFix">
        <div className="col-12 mb-5">
          <h3
            className="heading aos-init aos-animate"
            data-aos="zoom-in"
            data-aos-duration="1500"
          >
            Welcome to Deerwalk Auto Services
          </h3>
          <div className="heading-underline"></div>
          <h2
            className="heading aos-init aos-animate"
            data-aos="zoom-in"
            data-aos-duration="1500"
          >
            ONE STOP SOLUTION TO ALL YOUR CAR NEEDS
          </h2>

          <h3
            className="heading aos-init aos-animate"
            data-aos="zoom-in"
            data-aos-duration="1500"
          >
            AUTOMOBILE REPAIR SHOP RUN BY DEERWALK GROUP
          </h3>
        </div>
        <div className="text-center py-5">
          <div className="row mb-6">
            <div className="narrow mb-2">
              <p className="lead mt-5">
                {" "}
                DAS aims to be a standardized auto servicing center that caters
                to our customers by delivering high-quality service, parts, and
                repair. DAS has a goal to deliver honestly and accurately while
                quoting cost estimates and repair completion time estimates. DAS
                utilizes the highest degree of technology in managing the range
                of auto part inventory and building a strong customer base. Our
                strong relationship with our reputable vendors allows us to
                provide our customers with a genuine inventory of auto parts and
                excellence in service.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
