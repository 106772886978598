import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import api from "../../api";

import {
  Input,
  FormGroup,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  Button
} from "reactstrap";

export default class Feeds extends Component {
  state = {
    vehicles: [],
    customer: [],
    vehicle_no: "",
    full_name: "",
    contact_no: "",
    feedback_date: "",
    service_satisfaction: null,
    costTiming_satisfaction: null,
    recommendation: null,
    suggestion: null,
    feedbackList: [],
    viewFeedback: false,
    selectedFeedback: null
  };

  toggleViewFeedback = () => {
    this.setState({
      ...this.state,
      viewFeedback: !this.state.viewFeedback,
      selectedFeedback: null
    });
  };

  handleFeedbackView = feedback => {
    this.setState({
      ...this.state,
      selectedFeedback: feedback,
      viewFeedback: true
    });
  };

  componentDidMount = e => {
    this.getCustomerDetails();
    this.getVehicleDetails();
    this.getFeedbackList();
  };

  getVehicleDetails = () => {
    api.get("api/customer/vehicle/").then(response => {
      console.log(response.data);
      this.setState({
        ...this.state,
        vehicles: response.data
      });
    });
  };

  getCustomerDetails = () => {
    api.get("api/customer/").then(response => {
      console.log(response.data);
      this.setState({
        ...this.state,
        customer: response.data
      });
    });
  };

  getFeedbackList = () => {
    api.get("api/customer/feedback").then(response => {
      console.log(response.data);
      this.setState({
        ...this.state,
        feedbackList: response.data
      });
    });
  };

  render() {
    console.log("state : ", this.state);
    return (
      <Table>
        <thead>
          <tr>
            <th>Date</th>
            <th>Customer Name</th>
            <th>Vehicle No</th>
            <th>Feedback</th>
          </tr>
        </thead>

        {this.state.feedbackList &&
          this.state.feedbackList.map(feedback => {
            return (
              <tr>
                <td>{feedback.feedback_date}</td>
                <td>
                  {this.state.customer.length > 0 &&
                    this.state.customer.filter(
                      cus => cus.id == feedback.customer
                    )[0].full_name}
                </td>
                <td>
                  {this.state.vehicles.length > 0 &&
                    this.state.vehicles.filter(
                      veh => veh.customer == feedback.customer
                    )[0].vehicle_no}
                </td>
                <td>
                  <Button
                    color="primary"
                    size="sm"
                    className="mr-2"
                    onClick={() => this.handleFeedbackView(feedback)}
                  >
                    View
                  </Button>
                </td>
              </tr>
            );
          })}

        <Modal
          isOpen={this.state.viewFeedback}
          toggle={this.toggleViewFeedback}
        >
          <ModalHeader toggle={this.toggleViewFeedback}>
            <b>Feedback</b>
          </ModalHeader>
          {this.state.selectedFeedback && (
            <ModalBody>
              <div className="row">
                <div className="col-md-6 font-weight-bold">Feedback Date :</div>
                <div className="col-md-6 font-weight-bold">
                  {this.state.selectedFeedback.feedback_date}
                </div>
              </div>

              <div className="row">
                <div className="col-md-6 font-weight-bold">Customer Name :</div>
                <div className="col-md-6 font-weight-bold">
                  {this.state.customer.length > 0 &&
                    this.state.customer.filter(
                      cus => cus.id == this.state.selectedFeedback.customer
                    )[0].full_name}
                </div>
              </div>

              <div className="row">
                <div className="col-md-6 font-weight-bold">Vehicle No :</div>
                <div className="col-md-6 font-weight-bold">
                  {this.state.vehicles.length > 0 &&
                    this.state.vehicles.filter(
                      veh =>
                        veh.customer == this.state.selectedFeedback.customer
                    )[0].vehicle_no}
                </div>
              </div>
              <hr color="brown"></hr>
              <div className="row">
                <div className="col-md-6 font-weight-bold">
                  Service Satisfcation :{" "}
                </div>
                <div className="col-md-6 font-weight-bold">
                  {Boolean(this.state.selectedFeedback.service_satisfaction)
                    ? "Satisfied"
                    : "Not satified"}
                </div>
              </div>

              <div className="row">
                <div className="col-md-6 font-weight-bold">Cost & Timing :</div>
                <div className="col-md-6 font-weight-bold">
                  {Boolean(this.state.selectedFeedback.costTiming_satisfaction)
                    ? "Satisfied"
                    : "Not satisfied"}
                </div>
              </div>

              <div className="row">
                <div className="col-md-6 font-weight-bold">
                  Recommand Others:
                </div>
                <div className="col-md-6 font-weight-bold">
                  {Boolean(this.state.selectedFeedback.recommendation)
                    ? "Satisfied"
                    : "Not Satisfied"}
                </div>
              </div>

              <div className="row">
                <div className="col-md-6 font-weight-bold">Suggestions :</div>
                <div className="col-md-6 font-weight-bold">
                  {this.state.selectedFeedback.suggestion}
                </div>
              </div>
            </ModalBody>
          )}

          <ModalFooter>
            <Button
              color="danger"
              size="sm"
              className="mr-2"
              onClick={() => this.toggleViewFeedback()}
            >
              Close
            </Button>
          </ModalFooter>
        </Modal>
      </Table>
    );
  }
}
