import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import api from "../../api";
import swal from "sweetalert";

export default class Step3 extends Component {
  state = {
    services: [],
    services_option: [],
    bookings: "",
    status: "",
    remarks: "",
    pickup_type: { value: false, label: "No" },
    pickup_location: "",
    service_desc: "",
    startDate: new Date(),
    vehicle: {},
    redirectToReferrer: false
  };
  componentDidMount = () => {
    this.getServiceList();
    // const vehicle = JSON.parse(localStorage.getItem("vehicle"));
  };

  getServiceList = () => {
    api
      .get("api/staff/servicetype/")
      .then(response => {
        this.setState({
          // services: response.data
          services_option: response.data.map(data => ({
            value: data.id,
            label: data.service_type
          }))
        });
      })
      .catch(err => alert(err));
  };

  handleDate = date => {
    this.setState({
      startDate: date
    });
  };

  handleChange = e => {
    this.setState({
      ...this.state,
      [e.target.name]: e.target.value
    });
  };

  handleSelectChange = (val, key) => {
    console.log("Selected: " + key + " " + val);
    this.setState({
      ...this.state,
      [key]: val
    });
  };

  handleSubmit = (e, vehicle, customer) => {
    console.log("Handle vehicle", vehicle);
    e.preventDefault();
    let customer_id = null;
    if (customer.hasOwnProperty("id")) {
      console.log("I am a customer ", customer.id);
      customer_id = customer.id;
    } else if (vehicle) {
      console.log("I am a vehicle ", vehicle.customer);
      customer_id = vehicle.customer;
    }

    let services_id = [];
    this.state.services.map(item => {
      services_id.push(item.value);
    });

    const booking = {
      pickup_type: this.state.pickup_type.value,
      pickup_location: this.state.pickup_location,
      services: services_id,
      service_desc: this.state.service_desc,
      booking_date: this.state.startDate.toISOString().split("T")[0],
      customer: customer_id,
      vehicle: vehicle && vehicle.vehicle_no,
      jobcard_created: false
    };
    api
      .post("api/customer/booking/", booking)
      .then(response => {
        console.log(response);
        console.log(response.data);
        this.setState({
          bookings: response.data,
          redirectToReferrer: true
        });
        swal({
          text: "Booking Success!",
          icon: "success"
        });
      })
      .catch(error => {
        swal({
          text: "Booking Failed, Please fill the details correctly !!",
          icon: "error"
        });
      });
  };

  render() {
    console.log("from step 3 state", this.state);
    console.log("from step 3 props", this.props);
    if (this.state.redirectToReferrer) {
      return <Redirect to="/booking" />;
    }
    return (
      <div>
        <h3>Booking Details</h3>
        <hr color="blue"></hr>
        <form
          onSubmit={e =>
            this.handleSubmit(e, this.props.vehicle, this.props.customer)
          }
        >
          <div className="form-group">
            <label>Vehicle No</label>
            <input
              type="text"
              name="vehicle_no"
              className="form-control"
              disabled
              value={this.props.vehicle && this.props.vehicle.vehicle_no}
              onChange={this.handleChange}
            />
          </div>
          <div className="form-group">
            <label>Booking Date</label>
            <br />
            <DatePicker
              className="form-control"
              minDate={new Date()}
              selected={this.state.startDate}
              onChange={this.handleDate}
            />
          </div>
          <div className="form-group">
            <label>Pick Service</label>
            <Select
              name="pickup_type"
              value={this.state.pickup_type}
              options={[
                { label: "Yes", value: true },
                { label: "No", value: false }
              ]}
              onChange={val => this.handleSelectChange(val, "pickup_type")}
              isClearable={true}
              isSearchable={true}
            />
          </div>
          {this.state.pickup_type.value && (
            <div className="form-group">
              <label>Pickup Location </label>
              <input
                type="text"
                name="pickup_location"
                value={this.state.pickup_location}
                className="form-control"
                onChange={this.handleChange}
              />
            </div>
          )}
          <div className="form-group">
            <label>Service</label>
            <Select
              isMulti
              name="services"
              value={this.state.services}
              options={this.state.services_option}
              onChange={val => this.handleSelectChange(val, "services")}
              isClearable={true}
              isSearchable={true}
              required={true}
            />
          </div>

          <div className="form-group">
            <label>Service Descriptions...</label>
            <textarea
              type="text"
              row="5"
              name="service_desc"
              value={this.state.service_desc}
              className="form-control"
              onChange={this.handleChange}
            />
          </div>

          <div className="d-flex">
            <p>
              <button
                className="btn btn-primary btn-block"
                onClick={this.props.previousStep}
              >
                Back
              </button>
            </p>
            <p className="ml-2">
              <button
                className="btn btn-success btn-block"
                // onClick={this.props.nextStep}
                type="submit"
              >
                Submit
              </button>
            </p>
          </div>
        </form>
      </div>
    );
  }
}
