import React, { Component } from "react";
import api from "../../api";

export default class Step2 extends Component {
  state = {
    full_name: "",
    address: "",
    contact_no: "",
    edit: false
  };

  componentDidMount = () => {
    console.log("step 2");
    // this.setState({
    //   full_name: this.props.customer ? this.props.customer.full_name : "",
    //   address: this.props.customer ? this.props.customer.address : "",
    //   contact_no: this.props.customer ? this.props.customer.contact_no : ""
    // });
  };

  getCustomerList = () => {
    api
      .get("api/customer/" + 1)
      .then(response => {
        console.log(response);
        this.setState({
          customer: response.data
        });
      })
      .catch(err => alert(err));
  };

  handleChange = e => {
    this.setState({
      ...this.state,
      [e.target.name]: e.target.value
    });
  };

  handleDataSubmit = e => {
    e.preventDefault();
    const slug = this.props.vehicle.vehicle_no
      .toLowerCase()
      .split(" ")
      .join("-");

    const customer = {
      full_name: this.state.full_name,
      address: this.state.address,
      contact_no: this.state.contact_no
    };

    api.post("api/customer/", customer).then(response => {
      api
        .patch("api/customer/vehicle/" + slug, {
          customer: response.data.id
        })
        .then(response => {
          console.log("vehicle updated with customer");
          this.props.nextStep();
        })
        .catch(err => alert(err));
      // console.log(response);
      // console.log(response.data);
      this.setState({ customer: response.data });
      this.props.setCustomer(response.data);
      console.log("Success !");
    });
  };
  handleEditClick = e => {
    this.setState({
      edit: true,
      full_name: this.props.customer.full_name,
      address: this.props.customer.address,
      contact_no: this.props.customer.contact_no
    });
  };
  handleDataUpdate = e => {
    e.preventDefault();

    const customer = {
      full_name: this.state.full_name,
      address: this.state.address,
      contact_no: this.state.contact_no
    };
    api
      .patch("api/customer/" + this.props.vehicle.customer, customer)
      .then(response => {
        console.log("Customer Updated ! ");
        this.props.nextStep(3);
      })
      .catch(err => alert(err));
  };

  render() {
    console.log("step 2 states", this.state);
    console.log("step 2 props", this.props);
    return (
      <div>
        <h3>Customer Details</h3>
        <hr color="blue"></hr>
        {this.props.customer.hasOwnProperty("full_name") && (
          <div className="mt-3">
            <p>Name :{this.props.customer.full_name}</p>
            <p>Address: {this.props.customer.address}</p>
            <p>Contact: {this.props.customer.contact_no}</p>
            <div className="d-flex align-items-center mb-3">
              <button
                className="btn btn-primary ml-2"
                onClick={() => this.props.previousStep()}
              >
                Back
              </button>
              <button
                className="btn btn-info ml-2"
                onClick={this.handleEditClick}
              >
                Edit
              </button>
              <button
                className="btn btn-primary ml-2"
                onClick={this.props.nextStep}
              >
                Next
              </button>
            </div>
          </div>
        )}
        {(this.state.edit || !this.props.vehicle.customer) && (
          <form
            onSubmit={
              !this.state.edit ? this.handleDataSubmit : this.handleDataUpdate
            }
          >
            <div className="form-group">
              <label>Full Name</label>
              <input
                type="text"
                name="full_name"
                placeholder="Full Name"
                required={true}
                value={this.state.full_name}
                className="form-control"
                onChange={this.handleChange}
                isClearable={true}
                isSearchable={true}
              />
            </div>
            <div className="form-group">
              <label>Address</label>
              <input
                type="text"
                name="address"
                placeholder="Address"
                required={true}
                value={this.state.address}
                className="form-control"
                onChange={this.handleChange}
                isClearable={true}
                isSearchable={true}
              />
            </div>
            <div className="form-group">
              <label>Contact No</label>
              <input
                type="text"
                name="contact_no"
                placeholder="Contact"
                required={true}
                minLength="6"
                maxLength="10"
                value={this.state.contact_no}
                className="form-control"
                onChange={this.handleChange}
                isClearable={true}
                isSearchable={true}
              />
            </div>
            <div className="d-flex">
              <p className="ml-2">
                <button className="btn btn-success btn-block" type="submit">
                  {this.state.edit ? "Update and Next" : "Next"}
                </button>
              </p>
            </div>
          </form>
        )}
      </div>
    );
  }
}
