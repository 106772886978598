import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Select from "react-select";
import api from "../../api";
import swal from "sweetalert";

import {
  Input,
  FormGroup,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  Button
} from "reactstrap";

export default class Service extends Component {
  state = {
    searchText: "",
    services: [],
    newServiceData: {
      service_type: ""
    },
    editServiceData: {
      id: "",
      service_type: ""
    },
    newServiceModal: false,
    editServiceModal: false
  };
  componentWillMount() {
    const token = localStorage.getItem("token");
    if (token == null) {
      return this.props.history.push("/login");
    }
    this._refreshServices();
  }
  toggleNewServiceModal() {
    this.setState({
      newServiceModal: !this.state.newServiceModal
    });
  }
  toggleEditServiceModal() {
    this.setState({
      editServiceModal: !this.state.editServiceModal
    });
  }
  addService = e => {
    e.preventDefault();
    api
      .post("api/staff/servicetype/", this.state.newServiceData)
      .then(response => {
        let { services } = this.state;

        console.log("Service type", response.data);

        services.push(response.data);

        this.setState({
          services,
          newServiceModal: false,
          newServiceData: {
            service_type: ""
          }
        });

        swal({
          text: "Service successfully added !!",
          icon: "success"
        });
      })
      .catch(error => {
        swal({
          text: "Incorrect input, please enter the value correctly !!",
          icon: "error"
        });
      });
  };

  updateService() {
    let { service_type } = this.state.editServiceData;
    api
      .put(
        "api/staff/servicetype/" + this.state.editServiceData.id,
        {
          service_type
        }
        // {
        //   headers: { Authorization: "Token " + localStorage.getItem("token") }
        // }
      )
      .then(response => {
        this._refreshServices();

        this.setState({
          editServiceModal: false,
          editServiceData: { id: "", service_type: "" }
        });
      });
  }
  editService(id, service_type) {
    this.setState({
      editServiceData: { id, service_type },
      editServiceModal: !this.state.editServiceModal
    });
  }
  deleteService = id => {
    let bool = window.confirm("Do you want to delete?");
    // return console.log(bool);
    if (!bool) {
      return;
    }
    api
      .delete("api/staff/servicetype/" + id, {
        headers: { Authorization: "Token " + localStorage.getItem("token") }
      })
      .then(response => {
        this._refreshServices();
        this.setState({
          ...this.state,
          searchText: ""
        });
        //   swal({
        //     text: "Do you want to delete?",
        //     icon: "success"
        //   });
        // })
        // .catch(error => {
        //   swal({
        //     text: "Cancel!",
        //     icon: "error"
        //   });
      });
  };
  _refreshServices() {
    api.get("api/staff/servicetype/").then(response => {
      this.setState({
        services: response.data
      });
    });
  }
  onSearchChange = e => {
    let searchText = e.target.value;
    this.setState({
      ...this.state,
      searchText: searchText
    });
  };
  render() {
    console.log("state", this.state);
    let filteredData = this.state.services.filter(data => {
      if (this.state.searchText) {
        return data.service_type
          .toLowerCase()
          .includes(this.state.searchText.toLowerCase());
      } else {
        return data;
      }
    });
    let services = filteredData.map((service, i) => {
      return (
        <tr key={service.id}>
          <td>{i + 1}</td>
          <td>{service.service_type}</td>
          <td>
            <Button
              color="success"
              size="sm"
              className="mr-2"
              onClick={this.editService.bind(
                this,
                service.id,
                service.service_type
              )}
            >
              Edit
            </Button>
            <Button
              color="danger"
              size="sm"
              onClick={this.deleteService.bind(this, service.id)}
            >
              Delete
            </Button>
          </td>
        </tr>
      );
    });
    return (
      <div className="App container">
        <div className="d-flex align-items-center mb-2">
          <button
            className="d-block btn btn-primary btn-xs"
            // color="primary"
            onClick={this.toggleNewServiceModal.bind(this)}
          >
            Add New Service
          </button>
          <div className="ml-auto w-25">
            <input
              type="text"
              className="form-control"
              value={this.state.searchText}
              onChange={this.onSearchChange}
              placeholder="Search..."
              name="search"
            />
          </div>
        </div>
        <Modal
          isOpen={this.state.newServiceModal}
          toggle={this.toggleNewServiceModal.bind(this)}
        >
          <form onSubmit={this.addService}>
            <ModalHeader toggle={this.toggleNewServiceModal.bind(this)}>
              Add a new Service
            </ModalHeader>

            <ModalBody>
              <FormGroup>
                <Label for="service_type">Service Type</Label>
                <Input
                  required
                  id="service_type"
                  value={this.state.newServiceData.service_type}
                  onChange={e => {
                    let { newServiceData } = this.state;

                    newServiceData.service_type = e.target.value;

                    this.setState({ newServiceData });
                  }}
                />
              </FormGroup>
            </ModalBody>

            <ModalFooter>
              <input
                className="btn btn-primary"
                value="Add Service"
                type="submit"
              />

              <Button
                color="danger"
                onClick={this.toggleNewServiceModal.bind(this)}
              >
                Cancel
              </Button>
            </ModalFooter>
          </form>
        </Modal>

        <Modal
          isOpen={this.state.editServiceModal}
          toggle={this.toggleEditServiceModal.bind(this)}
        >
          <ModalHeader toggle={this.toggleEditServiceModal.bind(this)}>
            Edit a new Service
          </ModalHeader>
          <ModalBody>
            <FormGroup>
              <Label for="service_type">Service Type</Label>
              <Input
                id="service_type"
                value={this.state.editServiceData.service_type}
                onChange={e => {
                  let { editServiceData } = this.state;

                  editServiceData.service_type = e.target.value;

                  this.setState({ editServiceData });
                }}
              />
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.updateService.bind(this)}>
              Update Service
            </Button>{" "}
            <Button
              color="secondary"
              onClick={this.toggleEditServiceModal.bind(this)}
            >
              Cancel
            </Button>
          </ModalFooter>
        </Modal>

        <Table>
          <thead>
            <tr>
              <th>S.N</th>
              <th>Service Type</th>
              <th>Actions</th>
            </tr>
          </thead>

          <tbody>{services}</tbody>
        </Table>
      </div>
    );
  }
}
