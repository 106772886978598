import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import api from "../../api";
import {
  Input,
  FormGroup,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  Button
} from "reactstrap";
import swal from "sweetalert";

export default class User extends Component {
  state = {
    users: [],
    newUserData: {
      first_name: "",
      last_name: "",
      email: "",
      password: ""
    },
    editUserData: {
      id: "",
      password: ""
    },
    newUserModal: false,
    editUserModal: false
  };
  componentWillMount() {
    const token = localStorage.getItem("token");
    if (token == null) {
      return this.props.history.push("/login");
    }
    this._refreshUsers();
  }
  toggleNewUserModal() {
    this.setState({
      newUserModal: !this.state.newUserModal
    });
  }
  toggleEditUserModal() {
    this.setState({
      editUserModal: !this.state.editUserModal
    });
  }
  addUser() {
    api
      .post("api/user/", this.state.newUserData, {
        headers: { Authorization: "Token " + localStorage.getItem("token") }
      })
      .then(response => {
        let { users } = this.state;

        console.log("User name", response.data);

        users.push(response.data);

        this.setState({
          users,
          newUserModal: false,
          newUserData: {
            first_name: "",
            last_name: "",
            email: "",
            password: ""
          }
        });
        swal({
          text: "New user successfully added !!",
          icon: "success"
        });
      })
      .catch(error => {
        swal({
          text: "Incorrect input, failed to add new user !!",
          icon: "error"
        });
      });
  }

  updateUser() {
    let password = this.state.editUserData.password;

    api
      .put(
        "api/user/update-password/" + this.state.editUserData.id,
        {
          new_password: password
        },
        {
          headers: { Authorization: "Token " + localStorage.getItem("token") }
        }
      )
      .then(response => {
        this._refreshUsers();

        this.setState({
          editUserModal: false,
          editUserData: { id: "", password: "" }
        });
        swal({
          text: "Password successfully changed !!",
          icon: "success"
        });
      })
      .catch(error => {
        swal({
          text: "Password change failed !!",
          icon: "error"
        });
      });
  }
  editUser(id) {
    this.setState({
      editUserData: { id: id },
      editUserModal: !this.state.editUserModal
    });
  }
  deleteUser(id) {
    console.log(id);
    let bool = window.confirm("Do you want to delete?");
    // return console.log(bool);
    if (!bool) {
      return;
    }
    api
      .delete("api/user/" + id, {
        headers: { Authorization: "Token " + localStorage.getItem("token") }
      })
      .then(response => {
        this._refreshUsers();
      });
  }
  _refreshUsers() {
    api.get("api/user/").then(response => {
      this.setState({
        users: response.data
      });
    });
  }
  render() {
    console.log("state", this.state);
    let users = this.state.users.map((user, i) => {
      return (
        <tr key={user.id}>
          <td>{i + 1}</td>
          <td>{user.first_name}</td>
          <td>{user.last_name}</td>
          <td>{user.email}</td>
          <td>
            <Button
              color="success"
              size="sm"
              className="mr-2"
              onClick={this.editUser.bind(this, user.id)}
            >
              Change Password
            </Button>
            <Button
              color="danger"
              size="sm"
              onClick={this.deleteUser.bind(this, user.id)}
            >
              Delete
            </Button>
          </td>
        </tr>
      );
    });
    return (
      <div className="App container">
        <Button
          className="my-3"
          color="primary"
          onClick={this.toggleNewUserModal.bind(this)}
        >
          Add New User
        </Button>

        <Modal
          isOpen={this.state.newUserModal}
          toggle={this.toggleNewUserModal.bind(this)}
        >
          <ModalHeader toggle={this.toggleNewUserModal.bind(this)}>
            Add a new User
          </ModalHeader>
          <ModalBody>
            <FormGroup>
              <Label for="first_name">First Name</Label>
              <Input
                id="first_name"
                type="text"
                required={true}
                value={this.state.newUserData.first_name}
                onChange={e => {
                  let { newUserData } = this.state;

                  newUserData.first_name = e.target.value;

                  this.setState({ newUserData });
                }}
              />
            </FormGroup>
            <FormGroup>
              <Label for="last_name">Last Name</Label>
              <Input
                id="last_name"
                type="text"
                required={true}
                value={this.state.newUserData.last_name}
                onChange={e => {
                  let { newUserData } = this.state;

                  newUserData.last_name = e.target.value;

                  this.setState({ newUserData });
                }}
              />
            </FormGroup>
            <FormGroup>
              <Label for="email">Email</Label>
              <Input
                id="email"
                type="email"
                required={true}
                validate
                isClearable={true}
                value={this.state.newUserData.email}
                onChange={e => {
                  let { newUserData } = this.state;

                  newUserData.email = e.target.value;

                  this.setState({ newUserData });
                }}
              />
            </FormGroup>
            <FormGroup>
              <Label for="password">Password</Label>
              <Input
                id="password"
                type="password"
                required={true}
                validate={true}
                minLength="8"
                maxLength="20"
                isClearable={true}
                value={this.state.newUserData.password}
                onChange={e => {
                  let { newUserData } = this.state;

                  newUserData.password = e.target.value;

                  this.setState({ newUserData });
                }}
              />
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.addUser.bind(this)}>
              Add User
            </Button>{" "}
            <Button color="danger" onClick={this.toggleNewUserModal.bind(this)}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={this.state.editUserModal}
          toggle={this.toggleEditUserModal.bind(this)}
        >
          <ModalHeader toggle={this.toggleEditUserModal.bind(this)}>
            Change Password
          </ModalHeader>
          <ModalBody>
            {
              <FormGroup>
                <Label for="password">Type New Password</Label>
                <Input
                  id="password"
                  type="password"
                  required={true}
                  onChange={e => {
                    this.setState({
                      editUserData: {
                        ...this.state.editUserData,
                        password: e.target.value
                      }
                    });
                  }}
                />
              </FormGroup>
            }
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.updateUser.bind(this)}>
              Change Password
            </Button>{" "}
            <Button
              color="secondary"
              onClick={this.toggleEditUserModal.bind(this)}
            >
              Cancel
            </Button>
          </ModalFooter>
        </Modal>

        <Table>
          <thead>
            <tr>
              <th>S.N</th>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Email</th>
              <th>Actions</th>
            </tr>
          </thead>

          <tbody>{users}</tbody>
        </Table>
      </div>
    );
  }
}
