import React, { Component } from "react";
import "date-fns";
import {
  Input,
  FormGroup,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Table
} from "reactstrap";
import Select from "react-select";

export default class ServiceEditForm extends Component {
  state = {
    services: this.props.serviceValue ? this.props.serviceValue : []
  };

  handleFormChange = e => {
    console.log(e);
    let key = e.target.name;
    this.setState({
      ...this.state,
      [key]: e.target.value
    });
  };

  handleServiceFormChange = (key, value) => {
    this.setState({
      ...this.state,
      [key]: value
    });
  };

  handleFormSubmit = e => {
    e.preventDefault();
    console.log("from form", this.state);
    this.props.add(this.state);
  };

  render() {
    console.log(this.state);
    return (
      <div>
        <form onSubmit={this.handleFormSubmit}>
          <div className="form-group">
            <Label for="services">Services</Label>
            <Select
              isMulti
              name="services"
              onChange={value =>
                this.handleServiceFormChange("services", value)
              }
              value={this.state.services}
              options={this.props.serviceOption}
              isClearable={true}
              isSearchable={true}
            />
          </div>
          <br />
          <ModalFooter>
            <Button color="primary" type="submit">
              Save
            </Button>{" "}
            <Button color="secondary" onClick={this.props.close}>
              Cancel
            </Button>
          </ModalFooter>
        </form>
      </div>
    );
  }
}
