import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Select from "react-select";
import api from "../../api";

import {
  Input,
  FormGroup,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  Button
} from "reactstrap";
import swal from "sweetalert";

export default class Model extends Component {
  state = {
    models: [],
    companies: [],
    companies_option: [],
    newModelData: {
      model_name: "",
      company_name: ""
    },
    editModelData: {
      id: "",
      model_name: ""
    },
    newModelModal: false,
    editModelModal: false
  };

  componentDidMount() {
    const token = localStorage.getItem("token");
    if (token == null) {
      return this.props.history.push("/login");
    }
    this.getCompanyList();
    this._refreshModels();
  }

  toggleNewModelModal() {
    this.setState({
      newModelModal: !this.state.newModelModal
    });
  }
  toggleEditModelModal() {
    this.setState({
      editModelModal: !this.state.editModelModal
    });
  }

  handleChange = e => {
    this.setState({
      ...this.state,
      newmodelData: {
        ...this.state.newModelData,
        [e.target.name]: e.target.value
      }
    });
  };

  handleSelectChange = (val, key) => {
    console.log("Selected: " + val);
    this.setState({
      ...this.state,
      newModelData: {
        ...this.state.newModelData,
        [key]: val
      }
    });
  };
  handleSelectUpdateChange = (val, key) => {
    this.setState({
      ...this.state,
      editModelData: {
        ...this.state.editModelData,
        [key]: val
      }
    });
  };

  getCompanyList = () => {
    api
      .get("api/staff/companyname/")
      .then(response => {
        this.setState({
          companies_option: response.data.map(data => ({
            value: data.id,
            label: data.company_name
          })),
          companies: response.data
        });
      })
      .catch(err => alert(err));
  };

  addModel() {
    api
      .post(
        "api/staff/modelname/",
        {
          company: this.state.newModelData.company_name.value,
          model_name: this.state.newModelData.model_name
        }
        // {
        //   headers: { Authorization: "Token " + localStorage.getItem("token") }
        // }
      )
      .then(response => {
        let { models } = this.state;

        console.log("Model name", response.data);
        models.push(response.data);

        this.setState({
          models,
          newModelModal: false,
          newModelData: {
            company_name: "",
            model_name: ""
          }
        });
        // this._refreshModels();
        this.getCompanyList();
        swal({
          text: "Model successfully added !!",
          icon: "success"
        });
      })

      .catch(error => {
        swal({
          text: "Incorrect input, please enter the value correctly !!",
          icon: "error"
        });
      });
  }

  updateModel() {
    api
      .patch(
        "api/staff/modelname/" + this.state.editModelData.id,
        {
          model_name: this.state.editModelData.model_name,
          company: this.state.editModelData.company_name.value
        }
        // {
        //   headers: { Authorization: "Token " + localStorage.getItem("token") }
        // }
      )
      .then(response => {
        this._refreshModels();

        this.setState({
          editModelModal: false,
          editModelData: { id: "", model_name: "" }
        });
      });
  }

  editModel(model) {
    this.setState({
      editModelData: {
        model_name: model.model_name,
        company_name: this.state.companies_option.filter(
          company => company.value === model.company
        )[0],
        id: model.id
      },
      editModelModal: !this.state.editModelModal
    });
  }

  deleteModel(id) {
    let bool = window.confirm("Do you want to delete?");
    // return console.log(bool);
    if (!bool) {
      return;
    }
    api
      .delete("api/staff/modelname/" + id, {
        headers: { Authorization: "Token " + localStorage.getItem("token") }
      })
      .then(response => {
        this._refreshModels();
      });
  }
  _refreshModels() {
    let companyName = [];
    api.get("api/staff/modelname/").then(response => {
      this.setState({
        models: response.data
      });
    });
  }
  getCompanyName = id => {
    let company =
      this.state.companies &&
      this.state.companies.filter(company => company.id === id);
    if (company.length > 0) {
      return company[0].company_name;
    } else {
      return "";
    }
  };

  render() {
    console.log("state", this.state);
    let models =
      this.state.models &&
      this.state.models.map((model, i) => {
        return (
          <tr key={model.id}>
            <td>{i + 1}</td>
            <td>{this.getCompanyName(model.company)}</td>
            <td>{model.model_name}</td>

            <td>
              <Button
                color="success"
                size="sm"
                className="mr-2"
                onClick={this.editModel.bind(this, model)}
              >
                Edit
              </Button>
              <Button
                color="danger"
                size="sm"
                onClick={this.deleteModel.bind(this, model.id)}
              >
                Delete
              </Button>
            </td>
          </tr>
        );
      });
    return (
      <div className="App container">
        <Button
          className="my-3"
          color="primary"
          onClick={this.toggleNewModelModal.bind(this)}
        >
          Add New Model
        </Button>

        <Modal
          isOpen={this.state.newModelModal}
          toggle={this.toggleNewModelModal.bind(this)}
        >
          <ModalHeader toggle={this.toggleNewModelModal.bind(this)}>
            Add a new Model
          </ModalHeader>
          <ModalBody>
            <FormGroup>
              <Label for="company_name">Company Name</Label>
              <Select
                name="company_name"
                value={this.state.newModelData.company_name}
                options={this.state.companies_option}
                onChange={val => this.handleSelectChange(val, "company_name")}
              />
            </FormGroup>
            <FormGroup>
              <Label for="model_name">Model Name</Label>
              <Input
                id="model_name"
                value={this.state.newModelData.model_name}
                onChange={e => {
                  let { newModelData } = this.state;

                  newModelData.model_name = e.target.value;

                  this.setState({ newModelData });
                }}
              />
            </FormGroup>
          </ModalBody>

          <ModalFooter>
            <Button color="primary" onClick={this.addModel.bind(this)}>
              Add Model
            </Button>{" "}
            <Button
              color="danger"
              onClick={this.toggleNewModelModal.bind(this)}
            >
              Cancel
            </Button>
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={this.state.editModelModal}
          toggle={this.toggleEditModelModal.bind(this)}
        >
          <ModalHeader toggle={this.toggleEditModelModal.bind(this)}>
            Edit Model
          </ModalHeader>

          <ModalBody>
            <FormGroup>
              <Label for="company">Company Name</Label>
              <Select
                name="company"
                value={this.state.editModelData.company_name}
                options={this.state.companies_option}
                onChange={val =>
                  this.handleSelectUpdateChange(val, "company_name")
                }
                // isOptionSelected={true}
                isClearable={true}
                isSearchable={true}
              />
            </FormGroup>
            <FormGroup>
              <Label for="model_name">Model Name</Label>
              <Input
                id="model_name"
                value={this.state.editModelData.model_name}
                onChange={e => {
                  this.setState({
                    ...this.state,
                    editModelData: {
                      ...this.state.editModelData,
                      model_name: e.target.value
                    }
                  });
                }}
              />
            </FormGroup>
          </ModalBody>

          <ModalFooter>
            <Button color="primary" onClick={this.updateModel.bind(this)}>
              Update Model
            </Button>{" "}
            <Button
              color="danger"
              onClick={this.toggleEditModelModal.bind(this)}
            >
              Cancel
            </Button>
          </ModalFooter>
        </Modal>

        <Table>
          <thead>
            <tr>
              <th>S.N</th>
              <th>Company Name</th>
              <th>Model Name</th>
              <th>Actions</th>
            </tr>
          </thead>

          <tbody>{models}</tbody>
        </Table>
      </div>
    );
  }
}
