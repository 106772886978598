import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import api from "../../api";
import {
  Input,
  FormGroup,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  Button
} from "reactstrap";
import swal from "sweetalert";

export default class Company extends Component {
  state = {
    companies: [],
    newCompanyData: {
      company_name: ""
    },
    editCompanyData: {
      id: "",
      company_name: ""
    },
    newCompanyModal: false,
    editCompanyModal: false
  };
  componentWillMount() {
    const token = localStorage.getItem("token");
    if (token == null) {
      return this.props.history.push("/login");
    }
    this._refreshCompanies();
  }
  toggleNewCompanyModal() {
    this.setState({
      newCompanyModal: !this.state.newCompanyModal
    });
  }
  toggleEditCompanyModal() {
    this.setState({
      editCompanyModal: !this.state.editCompanyModal
    });
  }
  addCompany() {
    api
      .post("api/staff/companyname/", this.state.newCompanyData, {
        headers: { Authorization: "Token " + localStorage.getItem("token") }
      })
      .then(response => {
        let { companies } = this.state;

        console.log("Company name", response.data);

        companies.push(response.data);

        this.setState({
          companies,
          newCompanyModal: false,
          newCompanyData: {
            company_name: ""
          }
        });
        swal({
          text: "Company successfully added !!",
          icon: "success"
        });
      })
      .catch(error => {
        swal({
          text: "Incorrect input, please enter the value correctly !!",
          icon: "error"
        });
      });
  }

  updateCompany() {
    let { company_name } = this.state.editCompanyData;

    api
      .put(
        "api/staff/companyname/" + this.state.editCompanyData.id,
        {
          company_name
        },
        {
          headers: { Authorization: "Token " + localStorage.getItem("token") }
        }
      )
      .then(response => {
        this._refreshCompanies();

        this.setState({
          editCompanyModal: false,
          editCompanyData: { id: "", company_name: "" }
        });
      });
  }
  editCompany(id, company_name) {
    this.setState({
      editCompanyData: { id, company_name },
      editCompanyModal: !this.state.editCompanyModal
    });
  }
  deleteCompany(id) {
    let bool = window.confirm("Do you want to delete?");
    // return console.log(bool);
    if (!bool) {
      return;
    }
    api
      .delete("api/staff/companyname/" + id, {
        headers: { Authorization: "Token " + localStorage.getItem("token") }
      })
      .then(response => {
        this._refreshCompanies();
      });
  }
  _refreshCompanies() {
    api.get("api/staff/companyname/").then(response => {
      this.setState({
        companies: response.data
      });
    });
  }
  render() {
    console.log("state", this.state);
    let companies = this.state.companies.map((company, i) => {
      return (
        <tr key={company.id}>
          <td>{i + 1}</td>
          <td>{company.company_name}</td>
          <td>
            <Button
              color="success"
              size="sm"
              className="mr-2"
              onClick={this.editCompany.bind(
                this,
                company.id,
                company.company_name
              )}
            >
              Edit
            </Button>
            <Button
              color="danger"
              size="sm"
              onClick={this.deleteCompany.bind(this, company.id)}
            >
              Delete
            </Button>
          </td>
        </tr>
      );
    });
    return (
      <div className="App container">
        <Button
          className="my-3"
          color="primary"
          onClick={this.toggleNewCompanyModal.bind(this)}
        >
          Add New Company
        </Button>

        <Modal
          isOpen={this.state.newCompanyModal}
          toggle={this.toggleNewCompanyModal.bind(this)}
        >
          <ModalHeader toggle={this.toggleNewCompanyModal.bind(this)}>
            Add a new Company
          </ModalHeader>
          <ModalBody>
            <FormGroup>
              <Label for="company_name">Company Name</Label>
              <Input
                id="company_name"
                value={this.state.newCompanyData.company_name}
                onChange={e => {
                  let { newCompanyData } = this.state;

                  newCompanyData.company_name = e.target.value;

                  this.setState({ newCompanyData });
                }}
              />
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.addCompany.bind(this)}>
              Add Company
            </Button>{" "}
            <Button
              color="danger"
              onClick={this.toggleNewCompanyModal.bind(this)}
            >
              Cancel
            </Button>
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={this.state.editCompanyModal}
          toggle={this.toggleEditCompanyModal.bind(this)}
        >
          <ModalHeader toggle={this.toggleEditCompanyModal.bind(this)}>
            Edit a new Company
          </ModalHeader>
          <ModalBody>
            <FormGroup>
              <Label for="company_name">Company Name</Label>
              <Input
                id="company_name"
                value={this.state.editCompanyData.company_name}
                onChange={e => {
                  let { editCompanyData } = this.state;

                  editCompanyData.company_name = e.target.value;

                  this.setState({ editCompanyData });
                }}
              />
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.updateCompany.bind(this)}>
              Update Company
            </Button>{" "}
            <Button
              color="secondary"
              onClick={this.toggleEditCompanyModal.bind(this)}
            >
              Cancel
            </Button>
          </ModalFooter>
        </Modal>

        <Table>
          <thead>
            <tr>
              <th>S.N</th>
              <th>Company Name</th>
              <th>Actions</th>
            </tr>
          </thead>

          <tbody>{companies}</tbody>
        </Table>
      </div>
    );
  }
}
