import axios from "axios";

const {REACT_APP_API_URL}=process.env
const instance = axios.create({
  baseURL: REACT_APP_API_URL
});

instance.interceptors.request.use(
  config => {
    let token = localStorage.getItem("token");
    if (token) {
      config.headers["Authorization"] = `Token ${token}`;
    }
    return config;
  },
  error => Promise.reject(error)
);

export default instance;
