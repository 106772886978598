import React, { Component } from "react";
import MUIDataTable from "mui-datatables";
import Button from "@material-ui/core/Button";
import "bootstrap/dist/css/bootstrap.min.css";
import api from "../../api";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import JobCardForm from "./JobCardForm";
import ServiceEditForm from "./ServiceEditForm";

export default class AdminDashboard extends Component {
  state = {
    blist: [],
    disableButton: false,
    bookings: [],
    customerlist: [],
    servicelist: [],
    openStatusModel: false,
    selectedBooking: null,
    status: "",
    full_name: "",
    newJobCardModal: false,
    newEditServiceModel: false,
    selectedRow: [],
    jobcardDataList: [],
    jobcarddetail: {},
    currentServices: [],
    tempServices: [],
    vehicles: [],
    taskMonitorShow: false
  };
  componentWillMount() {
    const token = localStorage.getItem("token");
    if (token == null) {
      return this.props.history.push("/login");
    }
    this.getCustomerList();
    this.getServiceList();
    this.getVehicleList();
    this.getBookingList();
    this.getjobcardDataList();
  }

  toggleStatusModal = () => {
    this.setState({
      openStatusModel: !this.state.openStatusModel
    });

    api
      .patch("api/customer/booking/" + this.state.selectedBooking, {
        status: this.state.status,
        remarks: this.state.remarks
      })
      .then(response => {
        console.log("remarks response", response.data);
        this.getBookingList();
      });
  };

  getBookingList = () => {
    api
      .get("api/customer/booking/")
      .then(response => {
        console.log("booking response", response.data);
        let dataArr = [];
        response.data.map(dat => {
          dataArr.push([
            dat.id,
            dat.vehicle,
            dat.customer,
            dat.booking_date,
            dat.service_desc,
            dat.services,
            dat.pickup_type,
            dat.pickup_location,
            dat.status,
            dat.jobcard_created
          ]);
        });
        this.setState({
          ...this.state,
          blist: dataArr,
          bookings: response.data
        });
      })
      .catch(err => alert(err));
  };

  addJobCard = data => {
    this.setState({ ...this.state, disableButton: true });
    console.log("dashboard", data);
    console.log("booking data", this.state.selectedRow);
    const slug = this.state.selectedRow[1]
      .toLowerCase()
      .split(" ")
      .join("-");
    const jobcard = {
      service_time: data.service_time,
      registration_number: this.state.selectedRow[1],
      vat_number: data.vat_number,
      date: data.startDate,
      booking_service: this.state.selectedRow[0],
      reference_person: data.reference_person,
      reference_person_contact: data.reference_person_contact,
      jobcard_services: JSON.stringify(data.services.map(dat => dat.value)),
      jobcard_status: "Not Started"
    };
    api
      .post("api/staff/jobcard/", jobcard)
      .then(response => {
        api
          .patch("api/customer/vehicle/" + slug, {
            chasis: data.chasis
          })
          .then(response => {
            api
              .patch("api/customer/booking/" + this.state.selectedRow[0], {
                jobcard_created: true
              })
              .then(res => {
                this.getjobcardDataList();
                this.toggleNewJobCardModal();
                this.getBookingList();
                this.setState({ ...this.state, disableButton: false });
              });
          });
      })
      .catch(err => console.log(err));
  };

  addService = data => {
    console.log("dashboard", data);
    console.log("booking data", this.state.selectedRow);
    const service = {
      jobcard_services: JSON.stringify(data.services.map(dat => dat.value)),
      jobcard_status: "In Progress"
    };
    api
      .patch("api/staff/jobcard/" + this.state.selectedRow[0], service)
      .then(response => {
        this.getjobcardDataList();
        this.toggleEditServiceModel();
      })
      .catch(err => console.log(err));
  };

  getCustomerList = () => {
    api
      .get("api/customer/")
      .then(response => {
        this.setState({
          customerlist: response.data
        });
      })
      .catch(err => alert(err));
  };

  getServiceList = () => {
    api
      .get("api/staff/servicetype/")
      .then(response => {
        console.log("services", response.data);
        let temp = response.data.map(data => ({
          value: data.id,
          label: data.service_type
        }));
        this.setState({
          servicelist: response.data,
          tempServices: temp
        });
      })
      .catch(err => alert(err));
  };

  getServiceString = arr => {
    if (arr.length > 0) {
      var services =
        this.state.servicelist &&
        this.state.servicelist.filter(service => arr.includes(service.id));
      if (services && services.length > 0) {
        let data = services.map(s => {
          if (s.hasOwnProperty("service_type")) {
            return s.service_type;
          }
        });
        return data && data.join(", ");
      }
    }
  };

  getServiceOptions = arr => {
    if (arr.length > 0) {
      let services = arr.map(data => {
        let indService =
          this.state.servicelist &&
          this.state.servicelist.filter(service => service.id === data);
        return indService[0];
      });
      if (services.length > 0) {
        return (
          services &&
          services.map(data => ({
            label: data.service_type,
            value: data.id
          }))
        );
      } else {
        return "";
      }
    }
  };

  getjobcardDataList = () => {
    api
      .get("api/staff/jobcard/")
      .then(response => {
        let dataArr = [];
        response.data.map(dat => {
          dataArr.push([
            dat.id,
            dat.registration_number,
            dat.vat_number,
            dat.date,
            dat.reference_person,
            dat.reference_person_contact,
            dat.jobcard_services,
            dat.jobcard_status,
            dat.booking_service
          ]);
        });
        this.setState({
          jobcardDatalist: dataArr
        });
      })
      .catch(err => alert(err));
  };

  handleRemarks = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  handleButtonClick = (status, tableMeta) => {
    console.log(tableMeta);
    this.setState({
      selectedBooking: tableMeta.rowData[0],
      status: status,
      openStatusModel: true
    });
  };

  handleTaskMonitorClick = () => {
    this.setState({
      ...this.state,
      taskMonitorShow: !this.state.taskMonitorShow
    });
  };

  handleCellClick = (colData, rowMeta, rowIndex) => {
    console.log("coldata", colData);
    console.log("rowMeta", rowMeta);
    console.log("rowindex", rowIndex);
  };

  toggleNewJobCardModal = () => {
    this.setState({
      newJobCardModal: !this.state.newJobCardModal
    });
  };

  toggleEditServiceModel = () => {
    this.setState({
      newEditServiceModel: !this.state.newEditServiceModel
    });
  };

  handleCreateJobClick = tableData => {
    console.log("Individual row", tableData);

    let currentService = this.getServiceOptions(tableData[5]);
    this.setState({
      ...this.state,
      selectedRow: tableData,
      currentServices: currentService
    });
    this.toggleNewJobCardModal();
  };

  handleEditServiceClick = tableData => {
    console.log("Individual row", tableData);
    let data = JSON.parse(tableData[6]);
    let currentService = this.getServiceOptions(data);
    this.setState({
      ...this.state,
      selectedRow: tableData,
      currentServices: currentService
    });
    this.toggleEditServiceModel();
  };

  handleViewJobData = tableData => {
    let jobcarddetail = this.state.jobcarddatalist.filter(
      data => data.booking_service == tableData[0]
    )[0];
    this.setState({
      ...this.state,
      selectedRow: tableData,
      jobcarddetail: jobcarddetail
    });
    this.toggleViewJobModel();
  };

  handleStatusClick = (status, tableMeta) => {
    api
      .patch("/api/staff/jobcard/" + tableMeta.rowData[0], {
        jobcard_status: status
      })
      .then(response => {
        this.getjobcardDataList();
      });
  };

  getVehicleList = () => {
    api.get("api/customer/vehicle/").then(response => {
      this.setState({
        vehicles: response.data
      });
    });
  };
  render() {
    console.log(this.state);
    const test = this;
    const columns = [
      {
        name: "id",
        options: {
          display: false
        }
      },
      // "B-Id",
      "Vehicle Number",
      {
        name: "Customer",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            let customer =
              test.state.customerlist &&
              test.state.customerlist.filter(
                customer => customer.id == tableMeta.rowData[2]
              );
            if (customer.length > 0) {
              return customer[0].full_name;
            } else {
              return "";
            }
          }
        }
      },
      "Request Date",
      "Service Des",
      {
        name: "Services",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            // return (
            //   tableMeta.rowData[5].length > 0 &&
            //   test.getServiceString(tableMeta.rowData[5])
            // );
            let arr = tableMeta.rowData[5];
            if (arr.length > 0) {
              var services =
                test.state.servicelist &&
                test.state.servicelist.filter(service =>
                  arr.includes(service.id)
                );
              if (services && services.length > 0) {
                let data = services.map(s => {
                  return s.service_type;
                });
                return data && data.join(", ");
              }
            }
          }
        }
      },
      "Pick Up",
      "Pick Up location",

      "Status",
      {
        name: "Actions",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            return [
              tableMeta.rowData[8] == null && (
                <button
                  variant="contained"
                  color="primary"
                  onClick={e => test.handleButtonClick("accepted", tableMeta)}
                  className="btn btn-success ml-1"
                >
                  {tableMeta.rowData[8] == "accepted" ? (
                    "Accepted"
                  ) : (
                    <i class="fa fa-check" aria-hidden="true"></i>
                  )}
                </button>
              ),
              (tableMeta.rowData[8] == "rejected" ||
                tableMeta.rowData[8] == null) && (
                <button
                  onClick={e => test.handleButtonClick("rejected", tableMeta)}
                  variant="contained"
                  color="secondary"
                  disabled={tableMeta.rowData[8] == "rejected"}
                  className="btn btn-danger ml-1"
                >
                  {tableMeta.rowData[8] == "rejected" ? (
                    "Rejected"
                  ) : (
                    <i class="fa fa-times" aria-hidden="true"></i>
                  )}
                </button>
              ),
              tableMeta.rowData[8] == "accepted" && !tableMeta.rowData[9] && (
                <button
                  onClick={() => test.handleCreateJobClick(tableMeta.rowData)}
                  variant="contained"
                  disabled={test.state.disableButton}
                  color="secondary"
                  title="Create Job Card"
                  className="btn btn-primary ml-1"
                >
                  <i className="fa fa-plus"></i>
                </button>
              )
            ];
          }
        }
      }
    ];

    const columns2 = [
      {
        name: "id",
        options: {
          display: false
        }
      },
      "Vehicle Number",
      {
        name: "Customer Name",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            let vehicle =
              test.state.vehicles &&
              test.state.vehicles.filter(
                vehicle => vehicle.vehicle_no == tableMeta.rowData[1]
              );
            let customerId = vehicle && vehicle[0] && vehicle[0].customer;

            let customer =
              test.state.customerlist &&
              test.state.customerlist.filter(data => data.id == customerId);
            console.log(
              "fdafagadgasdgasdga",
              customer && customer[0] && customer[0].full_name
            );
            return customer && customer[0] && customer[0].full_name;
          }
        }
      },
      {
        name: "Requested Date",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            // return tableMeta.rowData[3];
            console.log("Date", typeof tableMeta.rowData[3].slice("-"));
            let d = tableMeta.rowData[3];
            if (d.split("-").length > 1) {
              // let date = new Date(d);
              return d.split("T")[0];
            } else {
              return d
                .split("/")
                .reverse()
                .join("-");
            }
            // let date = new Date(d)
          }
        }
      },
      "Reference Person",
      "Reference Person Contact",
      {
        name: "Services",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            // console.log("fdasfffffffffffff", tableMeta.rowData);
            let data = JSON.parse(tableMeta.rowData[6]);

            // console.log(tableMeta.rowData);
            return [
              test.getServiceString(data),
              <span
                onClick={() => test.handleEditServiceClick(tableMeta.rowData)}
                className="ml-1"
              >
                <i className="fa fa-edit"></i>
              </span>
            ];
          }
        }
      },
      {
        name: "Job Status",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            return tableMeta.rowData[7];
          }
        }
      },

      {
        name: "Job Actions",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            return [
              tableMeta.rowData[7] == "Not Started" && (
                <Button
                  onClick={e =>
                    test.handleStatusClick("In Progress", tableMeta)
                  }
                  variant="contained"
                  color="primary"
                >
                  {`Start`}
                </Button>
              ),
              tableMeta.rowData[7] == "In Progress" && (
                <Button
                  onClick={e => test.handleStatusClick("Completed", tableMeta)}
                  variant="contained"
                  color="secondary"
                >
                  {`Complete`}
                </Button>
              ),
              tableMeta.rowData[7] == "Completed" && (
                <Button disabled={true} variant="contained" color="secondary">
                  {`Completed`}
                </Button>
              )
            ];
          }
        }
      }
      // {
      //   name: "Booking ID",
      //   options: {
      //     display: false
      //   }
      // }
    ];

    const options = {
      filterType: "checkbox"
      // responsive: "scroll"
    };

    return (
      <div>
        <div className="d-flex justify-content-end w-100 mb-2">
          <button
            className="d-block btn btn-primary btn-xs"
            onClick={this.handleTaskMonitorClick}
          >
            {!this.state.taskMonitorShow
              ? "Go To Task Monitor"
              : "Go To Bookings"}
          </button>
        </div>

        {!this.state.taskMonitorShow && (
          <div>
            <MUIDataTable
              title={"Booking Request"}
              data={this.state.blist}
              columns={columns}
              options={options}
            />
          </div>
        )}
        {this.state.taskMonitorShow && (
          <div>
            <MUIDataTable
              title={"Task Monitor"}
              data={this.state.jobcardDatalist}
              columns={columns2}
              options={options}
            />
          </div>
        )}
        <Modal
          isOpen={this.state.newJobCardModal}
          toggle={this.toggleNewJobCardModal.bind(this)}
          size="lg"
        >
          <ModalHeader toggle={this.toggleNewJobCardModal.bind(this)}>
            <b>Create a Job Card for: {this.state.selectedRow[1]}</b>
          </ModalHeader>
          <ModalBody>
            <JobCardForm
              disableButton={this.state.disableButton}
              add={this.addJobCard}
              close={this.toggleNewJobCardModal}
              serviceValue={this.state.currentServices}
              serviceOption={this.state.tempServices}
            />
          </ModalBody>
        </Modal>

        <Modal
          isOpen={this.state.openStatusModel}
          toggle={this.toggleStatusModal}
        >
          <ModalHeader toggle={this.toggleStatusModal}>Remarks </ModalHeader>
          <div class="modal-body">
            <textarea
              class="form-control col-xs-12"
              name="remarks"
              value={this.state.remarks}
              onChange={e => this.handleRemarks(e)}
            ></textarea>
          </div>
          <ModalFooter>
            <Button color="primary" onClick={this.toggleStatusModal}>
              Set Remarks
            </Button>{" "}
            <Button color="danger" onClick={this.toggleStatusModal}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={this.state.newEditServiceModel}
          toggle={this.toggleEditServiceModel}
          size="lg"
        >
          <ModalHeader toggle={this.toggleEditServiceModel}>
            <b>Update Service for: {this.state.selectedRow[1]}</b>
          </ModalHeader>
          <ModalBody>
            <ServiceEditForm
              add={this.addService}
              close={this.toggleEditServiceModel}
              serviceValue={this.state.currentServices}
              serviceOption={this.state.tempServices}
            />
          </ModalBody>
        </Modal>
      </div>
    );
  }
}
