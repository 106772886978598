import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { MDBContainer, MDBRow, MDBCol, MDBInput, MDBBtn } from "mdbreact";
import { Redirect } from "react-router-dom";
import api from "../../api";
export default class Login extends Component {
  constructor() {
    super();
    this.state = {
      username: "",
      password: "",
      loggedIn: false
    };
    this.login = this.login.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  componentWillMount() {
    if (sessionStorage.getItem("token")) this.props.loginSuccess();
  }
  login(e) {
    e.preventDefault();
    if (this.state.username && this.state.password) {
      api
        .post("api/token-auth/", {
          username: this.state.username,
          password: this.state.password
        })
        .then(respone => {
          localStorage.setItem("token", respone.data.token);
          this.props.history.push("/admin/admindashboard");
        })
        .catch(err => console.log(err));
    }
  }

  render() {
    console.log("logged_in: ", this.props.loggedIn);
    if (this.state.loggedIn) {
      return <Redirect to="/admin" />;
    }

    return (
      <MDBContainer>
        <MDBRow>
          <MDBCol md="6 mt-5 ml-5">
            <form onSubmit={this.login}>
              <p className="h5 text-center mb-4">Sign in</p>
              <div className="grey-text">
                <MDBInput
                  name="username"
                  label="Type your email"
                  icon="envelope"
                  group
                  type="text"
                  required={true}
                  value={this.state.username}
                  onChange={this.onChange}
                  validate
                  error="wrong"
                  success="right"
                  isClearable={true}
                />
                <MDBInput
                  name="password"
                  label="Type your password"
                  icon="lock"
                  group
                  type="password"
                  required={true}
                  value={this.state.password}
                  onChange={this.onChange}
                  validate
                  isClearable={true}
                />
              </div>
              <div className="text-center">
                <MDBBtn color="primary" type="submit" onClick={this.login}>
                  Login
                </MDBBtn>
              </div>
            </form>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    );
  }
}
